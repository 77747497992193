import React from "react";
import styled from "styled-components";
// Assets
import QuotesIcon from "../../assets/svg/Quotes";
export default function Address() {
  return (
    <Wrapper id="contactez">
      <IframeAddress
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3316.6731714548882!2d-7.992310784473361!3d31.621084536867407!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xdafef930918ded5%3A0x93791927300b6566!2sELBOUSTANI%20TRANSPORT%20INTERNATIONAL!5e0!3m2!1sfr!2sma!4v1665997814128!5m2!1sfr!2sma"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></IframeAddress>
      <QuoteWrapper className="flexCenter darkBg radius8">
        <QuotesWrapper>
          <QuotesIcon />
        </QuotesWrapper>
        <div>
          <QuotePar className="font15 whiteColor">
            <i className="pi pi-map"></i>
            <span>Arset Lamaach Rue Okba Ben Nafii N10 Mag N 2</span>
          </QuotePar>
          <QuotePar className="font15 whiteColor">
            <i className="pi pi-phone"></i>
            <span>+212 673 835 457</span>
          </QuotePar>
          <QuotePar className="font15 whiteColor">
            <i className="pi pi-envelope"></i>
            <span>Elboustani.transport@gmail.com</span>
          </QuotePar>
          <QuotePar className="font15 whiteColor">
            <i className="pi pi-facebook"></i>
            <span>ELBOUSTANI TRANSPORT INTERNATIONAL</span>
          </QuotePar>
          <QuotePar className="font15 whiteColor">
            <i className="pi pi-instagram"></i>
            <span>ELBOUSTANI TRANSPORT INTERNATIONAL</span>
          </QuotePar>
        </div>
      </QuoteWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 700px;
`;
const IframeAddress = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
`;
const QuoteWrapper = styled.div`
  top: 25%;
  left: 10%;
  position: absolute;
  width: 400px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 700px) {
    width: 300px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const QuotePar = styled.p`
  display: flex;
  margin: 20px 0;
  align-items: center;
  i {
    margin-right: 10px;
  }
  span {
    padding-top: 5px;
  }
`;
