import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../Sections/Devis";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";

var DateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
export const DepartForm = ({ next, back }) => {
  const { data, setData } = useContext(DataContext);
  const [pays, setPays] = useState("");
  const [ville, setVille] = useState("");
  const [adresse, setAdresse] = useState("");
  const [code_postal, setCode_postal] = useState("");
  const [date, setDate] = useState();
  const [local, setLoacl] = useState(null);
  const [etage, setEtage] = useState(null);
  const [minDate, setMinDate] = useState(new Date());
  const etages = [
    "Rez de chaussée",
    "1er Étage",
    "2eme Étage",
    "3eme Étage",
    "4eme Étage",
    "5eme Étage",
    "6eme Étage",
    "7eme Étage",
    "8eme Étage",
    "9eme Étage",
    "10eme Étage",
    "11eme Étage",
    "12eme Étage",
    "13eme Étage",
    "14eme Étage",
    "15eme Étage",
    "16eme Étage",
    "17eme Étage",
    "18eme Étage",
    "19eme Étage",
    "20eme Étage",
  ];

  const handleSubmit = (e) => {
    data.depart = {
      pays,
      ville,
      adresse,
      code_postal,
      date,
      local,
      etage,
    };
    setData(data);
    next();
    e.preventDefault();
  };

  useEffect(() => {
    if (data.depart) {
      setPays(data.depart.pays);
      setVille(data.depart.ville);
      setAdresse(data.depart.adresse);
      setCode_postal(data.depart.code_postal);
      setDate(data.depart.date);
      setLoacl(data.depart.local);
      setEtage(data.depart.etage);
    }
  }, []);
  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="card">
        <div className="mb-3 formgrid grid">
          <div className="field col">
            <span className="p-float-label">
              <InputText
                required
                className=" block w-full"
                id="pays"
                value={pays}
                onChange={(e) => setPays(e.target.value)}
              />
              <label htmlFor="pays">Pays de départ*</label>
            </span>
          </div>
          <div className="field col">
            <span className="p-float-label">
              <InputText
                required
                className=" block w-full"
                id="ville"
                value={ville}
                onChange={(e) => setVille(e.target.value)}
              />
              <label htmlFor="ville">Ville de départ*</label>
            </span>
          </div>
        </div>

        <div className="p-fluid formgrid grid">
          <div className="mb-5 field col-12 md:col-8">
            <span className="p-float-label">
              <InputText
                required
                className=" block w-full"
                id="adresse"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
              />
              <label htmlFor="adresse">Adresse de départ*</label>
            </span>
          </div>
          <div className="mb-5 field col-12 md:col-4">
            <span className="p-float-label">
              <InputText
                className=" block w-full"
                id="code_postal"
                value={code_postal}
                onChange={(e) => setCode_postal(e.target.value)}
              />
              <label htmlFor="code_postal">Code postal</label>
            </span>
          </div>
        </div>
        <div className="mb-5 field">
          <span className="p-float-label">
            <Calendar
              required
              className="text-base surface-overlay surface-border border-round appearance-none outline-none focus:border-primary w-full"
              id="date"
              dateFormat="dd/mm/yy"
              mask="99/99/9999"
              showIcon
              minDate={minDate}
              value={date}
              onChange={(e) =>
                setDate(e.target.value.toLocaleDateString("fr-FR", DateOptions))
              }
            />
            <label htmlFor="date">Date de départ*</label>
          </span>
        </div>
        <h4 className="my-3">Vous déménagez à partir de :</h4>
        <div className="mb-3 formgrid grid">
          <div className="field col">
            <div className="flex flex-wrap justify-content-between flex-wrap card-container indigo-container">
              <div className="field-radiobutton">
                <RadioButton
                  required
                  inputId="Bureau"
                  value="Bureau"
                  onChange={(e) => {
                    setLoacl(e.value);
                  }}
                  checked={local === "Bureau"}
                />
                <label className="pt-1" htmlFor="Bureau">
                  Bureau
                </label>
              </div>
              <div className="field-radiobutton">
                <RadioButton
                  required
                  inputId="Maison"
                  value="Maison"
                  onChange={(e) => {
                    setLoacl(e.value);
                  }}
                  checked={local === "Maison"}
                />
                <label className="pt-1" htmlFor="Maison">
                  Maison
                </label>
              </div>
              <div className="field-radiobutton">
                <RadioButton
                  required
                  inputId="Appartement"
                  value="Appartement"
                  onChange={(e) => {
                    setLoacl(e.value);
                  }}
                  checked={local === "Appartement"}
                />
                <label className="pt-1" htmlFor="Appartement">
                  Appartement
                </label>
              </div>
              <div className="field-radiobutton">
                <RadioButton
                  required
                  inputId="Autre"
                  value="Autre"
                  onChange={(e) => {
                    setLoacl(e.value);
                  }}
                  checked={local === "Autre"}
                />
                <label className="pt-1" htmlFor="Autre">
                  Autre
                </label>
              </div>
            </div>
          </div>
        </div>

        <h4 className="my-3">À quels étages allez-vous déménager ?</h4>
        <div className="field">
          <MultiSelect
            value={etage}
            options={etages}
            onChange={(e) => setEtage(e.value)}
            placeholder="sélectionner vos étages"
            display="chip"
            className="w-full"
          />
        </div>
      </div>
      <div className="flex justify-content-between flex-wrap card-container">
        <Button
          className="align-items-end flex p-button p-button-outlined p-button-secondary p-component"
          label="Back"
          icon="pi pi-times"
          onClick={back}
        />
        <Button
          className="p-button-outlined p-button-info"
          label="Suivant"
          iconPos="right"
          icon="pi pi-angle-right"
          type="submit"
        />
      </div>
    </form>
  );
};
