import React from "react";
import styled from "styled-components";
import ENTREPOSAGE from "../../assets/img/blog/ENTREPOSAGE.webp";
import MARITIME from "../../assets/img/blog/MARITIME.webp";
import PROFESSIONNEL from "../../assets/img/blog/PROFESSIONNEL.webp";

export default function AproposBox({ icon, title, text }) {
  let getIcon;

  switch (icon) {
    case "ENTREPOSAGE":
      getIcon = ENTREPOSAGE;
      break;
    case "MARITIME":
      getIcon = MARITIME;
      break;
    case "PROFESSIONNEL":
      getIcon = PROFESSIONNEL;
      break;
    default:
      getIcon = PROFESSIONNEL;
      break;
  }
  return (
    <WrapperBtn className="animate pointer">
      <Wrapper className="whiteBg radius8 shadow">
        <ImgStyle src={getIcon}></ImgStyle>
        <h3 className="font20 extraBold">{title}</h3>
        <p className="font13" style={{ padding: "30px 0" }}>
          {text}
        </p>
      </Wrapper>
    </WrapperBtn>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px 30px;
  margin-top: 30px;
`;
const WrapperBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  :hover {
    transform: scale(1.1);
  }
`;
const ImgStyle = styled.img`
  height: 80px;
  width: 80px;
  margin-bottom: 20px;
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
