import React from "react";
import styled from "styled-components";
// Assets
import HeroImage from "../../assets/img/hero/move2.webp";

export default function Hero() {
  return (
    <Wrapper>
      <Img src={HeroImage} alt="office" />
      <HeroTitle>
        <h1 className="extraBold font60">
          Demandez votre devis <br /> gratuit et sur-mesure
        </h1>
      </HeroTitle>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const Img = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  filter: contrast(0.5);
`;
const HeroTitle = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  text-align: center;
  width: 100%;
  transform: translate(-50%, -50%);
`;
const HeroP = styled.p`
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
`;
