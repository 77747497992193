import { createContext, useState } from "react";
import styled from "styled-components";
import { Steps } from "primereact/steps";
import { InfoForm } from "../Elements/infoForm";
import { DepartForm } from "../Elements/departForm";
import { DestinationForm } from "../Elements/destinationForm";
import { DetailsForm } from "../Elements/DetailsForm";
import { Player } from "@lottiefiles/react-lottie-player";
import { Link } from "react-router-dom";
import axios from "axios";

const baseURL = "https://elboustanitrans.com/elboustaniback/";
export const DataContext = createContext();
export default function Devis() {
  const items = [
    { label: "Information personnelle" },
    { label: "Lieu de départ" },
    { label: "Destination" },
    { label: "Détails du déménagement" },
  ];
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState({});
  const [email, setEmail] = useState(true);

  const envoye = () => {
    axios
      .post(baseURL, data)
      .then((response) => {
        setEmail(true);
        setActiveIndex(-1);
      })
      .catch((err) => {
        setEmail(false);
        setActiveIndex(-1);
      });
  };
  const reset = () => {
    setActiveIndex(0);
    setEmail(true);
  };
  return (
    <Wrapper>
      <SectionDevis id="sectionDevis">
        {activeIndex == -1 ? (
          <Done>
            {email ? (
              <>
                <Player
                  src="https://assets9.lottiefiles.com/packages/lf20_benzmfne.json"
                  className="player"
                  loop
                  autoplay
                  style={{ height: "400px", width: "400px" }}
                />
                <div>
                  <span>Votre demande a bien été envoyée</span>
                  <span>Nous vous contacterons bientôt</span>
                  <span>Merci pour ta confiance</span>
                </div>
                <Link
                  to="/"
                  className="radius8 lightBg"
                  style={{ padding: "10px 15px" }}
                >
                  retour à la page d'accueil
                </Link>
              </>
            ) : (
              <>
                <div>
                  <span>il y a un problème merci de réessayer</span>
                </div>
                <Player
                  src="https://assets2.lottiefiles.com/packages/lf20_bhw1ul4g.json"
                  className="player"
                  loop
                  autoplay
                  style={{ height: "400px", width: "400px" }}
                />
                <Link
                  onClick={reset}
                  className="radius8 lightBg"
                  style={{ padding: "10px 15px" }}
                >
                  Veuillez remplir à nouveau le demande
                </Link>
              </>
            )}
          </Done>
        ) : (
          <>
            <DevisDesc>
              Remplissez le formulaire ci-dessous pour recevoir un devis
              personnalise. Nous prendrons contact avec vous pour etudier vos
              besoins. <br /> N'hesitez pas a nous en dire plus sur votre
              demenagement en commentaire !
            </DevisDesc>
            <SteperContainer>
              <Steps model={items} activeIndex={activeIndex} readOnly={true} />
              <DataContext.Provider value={{ data, setData }}>
                {activeIndex === 0 ? (
                  <InfoForm
                    data={data}
                    next={() => setActiveIndex(activeIndex + 1)}
                  />
                ) : activeIndex === 1 ? (
                  <DepartForm
                    data={data}
                    next={() => setActiveIndex(activeIndex + 1)}
                    back={() => setActiveIndex(activeIndex - 1)}
                  />
                ) : activeIndex === 2 ? (
                  <DestinationForm
                    data={data}
                    next={() => setActiveIndex(activeIndex + 1)}
                    back={() => setActiveIndex(activeIndex - 1)}
                  />
                ) : activeIndex === 3 ? (
                  <DetailsForm
                    data={data}
                    envoye={envoye}
                    back={() => setActiveIndex(activeIndex - 1)}
                  />
                ) : (
                  "unknown step"
                )}
              </DataContext.Provider>
            </SteperContainer>
          </>
        )}
      </SectionDevis>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #e5e5e5;
`;
const SectionDevis = styled.section`
  width: 90%;
  transform: translateY(-70px);
  background: white;
  top: -10%;
  box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%), 0 6px 30px 5px rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(0 0 0 / 20%);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const DevisDesc = styled.span`
  text-align: center;
  margin: 80px;
  font-weight: 600;
`;

const SteperContainer = styled.div`
  width: 100%;
  form {
    width: 80%;
    margin: 50px auto;
  }
  @media (max-width: 800px) {
    div {
      ul {
        flex-direction: column;
        li {
          margin: 10px 0;
        }
      }
    }
  }
`;
const Done = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  span {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px;
  }
`;
