import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../Sections/Devis";
import { Button } from "primereact/button";

export const DetailsForm = ({ envoye, back }) => {
  const { data, setData } = useContext(DataContext);
  const [television, setTelevision] = useState({
    checked: false,
    value: null,
  });
  const [machineALaver, setMachineALaver] = useState({
    checked: false,
    value: null,
  });
  const [refrigerateur, setRefrigerateur] = useState({
    checked: false,
    value: null,
  });
  const [miroir, setMiroir] = useState({
    checked: false,
    value: null,
  });
  const [table, setTable] = useState({
    checked: false,
    value: null,
  });
  const [chaise, setChaise] = useState({
    checked: false,
    value: null,
  });
  const [bureau, setBureau] = useState({
    checked: false,
    value: null,
  });
  const [armoire, setArmoire] = useState({
    checked: false,
    value: null,
  });
  const [velo, setVelo] = useState({
    checked: false,
    value: null,
  });
  const [colis, setColis] = useState({
    checked: false,
    value: null,
  });
  const [bois, setBois] = useState({
    checked: false,
    value: null,
  });
  const [canape, setCanape] = useState({
    checked: false,
    value: null,
  });
  const [autre, setAutre] = useState({
    checked: false,
    value: "",
  });
  const [typeDEtransfert, setTypeDEtransfert] = useState(null);
  const [typeDEdemenagement, setTypeDEdemenagement] = useState(null);
  const [demenagementDetails, setDemenagementDetails] = useState(null);

  const type_transfert = [
    "Transporté par voie terrestre",
    "Transporté par mer",
  ];
  const type_demenagement = [
    "Déménagement professionnel",
    "Déménagement particulier",
  ];
  const demenagement_details = [
    "Emballage et déballage",
    "Besoin d'un garde-meubles (Actimovers)",
  ];

  const handleSubmit = (e) => {
    const detailsValue = {};
    const listChecked = [];
    if (television.checked) {
      listChecked.push({
        name: "télévisions",
        value: television.value,
      });
    }
    if (machineALaver.checked) {
      listChecked.push({
        name: "Machines à laver",
        value: machineALaver.value,
      });
    }
    if (refrigerateur.checked) {
      listChecked.push({
        name: "Réfrigérateurs",
        value: refrigerateur.value,
      });
    }
    if (miroir.checked) {
      listChecked.push({
        name: "Miroirs",
        value: miroir.value,
      });
    }
    if (table.checked) {
      listChecked.push({
        name: "Tables",
        value: table.value,
      });
    }
    if (chaise.checked) {
      listChecked.push({
        name: "Chaises",
        value: chaise.value,
      });
    }
    if (bureau.checked) {
      listChecked.push({
        name: "Bureaux",
        value: bureau.value,
      });
    }
    if (armoire.checked) {
      listChecked.push({
        name: "Armoires",
        value: armoire.value,
      });
    }
    if (velo.checked) {
      listChecked.push({
        name: "Vélos",
        value: velo.value,
      });
    }
    if (colis.checked) {
      listChecked.push({
        name: "Colis",
        value: colis.value,
      });
    }
    if (bois.checked) {
      listChecked.push({
        name: "Bois",
        value: bois.value,
      });
    }
    if (canape.checked) {
      listChecked.push({
        name: "Canapé",
        value: canape.value,
      });
    }
    if (autre.checked) {
      detailsValue.autre = autre.value;
    }
    if (listChecked.length > 0) {
      detailsValue.meubles = listChecked;
    }
    detailsValue.typeDEtransfert = typeDEtransfert;
    detailsValue.typeDEdemenagement = typeDEdemenagement;
    detailsValue.demenagementDetails = demenagementDetails;
    data.details = detailsValue;
    setData(data);
    envoye();
    e.preventDefault();
  };
  useEffect(() => {
    if (data.details) {
      if (data.details.meubles) {
        data.details.meubles.forEach((element) => {
          if ("télévisions" == element.name) {
            setTelevision({
              checked: true,
              value: element.value,
            });
          }
          if ("Machines" == element.name) {
            setMachineALaver({
              checked: true,
              value: element.value,
            });
          }
          if ("Réfrigérateurs" == element.name) {
            setRefrigerateur({
              checked: true,
              value: element.value,
            });
          }
          if ("Miroirs" == element.name) {
            setMiroir({
              checked: true,
              value: element.value,
            });
          }
          if ("Tables" == element.name) {
            setTable({
              checked: true,
              value: element.value,
            });
          }
          if ("Chaises" == element.name) {
            setChaise({
              checked: true,
              value: element.value,
            });
          }
          if ("Bureaux" == element.name) {
            setBureau({
              checked: true,
              value: element.value,
            });
          }
          if ("Armoires" == element.name) {
            setArmoire({
              checked: true,
              value: element.value,
            });
          }
          if ("Vélos" == element.name) {
            setVelo({
              checked: true,
              value: element.value,
            });
          }
          if ("Colis" == element.name) {
            setColis({
              checked: true,
              value: element.value,
            });
          }
          if ("Bois" == element.name) {
            setBois({
              checked: true,
              value: element.value,
            });
          }
          if ("Canapé" == element.name) {
            setCanape({
              checked: true,
              value: element.value,
            });
          }
        });
      }
      if (data.details.autre) {
        setAutre({
          checked: true,
          value: data.details.autre,
        });
      }
      setTypeDEtransfert(data.details.typeDEtransfert);
      setTypeDEdemenagement(data.details.typeDEdemenagement);
      setDemenagementDetails(data.details.demenagementDetails);
    }
  }, []);
  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="card">
        <div className="grid p-fluid">
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Dropdown
                required
                inputid="TypeDEtransfert"
                value={typeDEtransfert}
                options={type_transfert}
                onChange={(e) => setTypeDEtransfert(e.value)}
              />
              <label htmlFor="TypeDEtransfert">Type de transfert*</label>
            </span>
          </div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Dropdown
                required
                inputid="TypeDEdemenagement"
                value={typeDEdemenagement}
                options={type_demenagement}
                onChange={(e) => setTypeDEdemenagement(e.value)}
              />
              <label htmlFor="TypeDEdemenagement">Type de déménagement*</label>
            </span>
          </div>
          <div className="field col-12 md:col-4">
            <span className="p-float-label">
              <Dropdown
                required
                inputid="demenagementDetails"
                value={demenagementDetails}
                options={demenagement_details}
                onChange={(e) => setDemenagementDetails(e.value)}
              />
              <label htmlFor="demenagementDetails">Détails*</label>
            </span>
          </div>
        </div>
        <h4 className="my-3">
          Choisissez les choses qui seront déménagés et précisez leur quantité
        </h4>
        <div className="grid p-fluid">
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={television.checked}
                  onChange={(e) =>
                    setTelevision({
                      checked: !television.checked,
                      value: television.checked ? null : television.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={television.checked}
                  value={television.value}
                  onChange={(e) =>
                    setTelevision({
                      checked: television.checked,
                      value: e.value,
                    })
                  }
                  inputid="television"
                  disabled={!television.checked}
                />
                <label htmlFor="television">
                  {television.checked
                    ? "Le nombre des télévisions"
                    : "Télévision"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={machineALaver.checked}
                  onChange={(e) =>
                    setMachineALaver({
                      checked: !machineALaver.checked,
                      value: machineALaver.checked ? null : machineALaver.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={machineALaver.checked}
                  value={machineALaver.value}
                  onChange={(e) =>
                    setMachineALaver({
                      checked: machineALaver.checked,
                      value: e.value,
                    })
                  }
                  inputid="machineALaver"
                  disabled={!machineALaver.checked}
                />
                <label htmlFor="machineALaver">
                  {machineALaver.checked
                    ? "Le nombre des Machines à laver"
                    : "Machine à laver"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={refrigerateur.checked}
                  onChange={(e) =>
                    setRefrigerateur({
                      checked: !refrigerateur.checked,
                      value: refrigerateur.checked ? null : refrigerateur.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={refrigerateur.checked}
                  value={refrigerateur.value}
                  onChange={(e) =>
                    setRefrigerateur({
                      checked: refrigerateur.checked,
                      value: e.value,
                    })
                  }
                  inputid="refrigerateur"
                  disabled={!refrigerateur.checked}
                />
                <label htmlFor="refrigerateur">
                  {refrigerateur.checked
                    ? "Le nombre des Réfrigérateurs"
                    : "Réfrigérateur"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={miroir.checked}
                  onChange={(e) =>
                    setMiroir({
                      checked: !miroir.checked,
                      value: miroir.checked ? null : miroir.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={miroir.checked}
                  value={miroir.value}
                  onChange={(e) =>
                    setMiroir({
                      checked: miroir.checked,
                      value: e.value,
                    })
                  }
                  inputid="miroir"
                  disabled={!miroir.checked}
                />
                <label htmlFor="miroir">
                  {miroir.checked ? "Le nombre des Miroirs" : "Miroir"}
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="grid p-fluid">
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={table.checked}
                  onChange={(e) =>
                    setTable({
                      checked: !table.checked,
                      value: table.checked ? null : table.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={table.checked}
                  value={table.value}
                  onChange={(e) =>
                    setTable({
                      checked: table.checked,
                      value: e.value,
                    })
                  }
                  inputid="table"
                  disabled={!table.checked}
                />
                <label htmlFor="table">
                  {table.checked ? "Le nombre des Tables" : "Table"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={chaise.checked}
                  onChange={(e) =>
                    setChaise({
                      checked: !chaise.checked,
                      value: chaise.checked ? null : chaise.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={chaise.checked}
                  value={chaise.value}
                  onChange={(e) =>
                    setChaise({
                      checked: chaise.checked,
                      value: e.value,
                    })
                  }
                  inputid="chaise"
                  disabled={!chaise.checked}
                />
                <label htmlFor="chaise">
                  {chaise.checked ? "Le nombre des Chaises" : "Chaise"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={bureau.checked}
                  onChange={(e) =>
                    setBureau({
                      checked: !bureau.checked,
                      value: bureau.checked ? null : bureau.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={bureau.checked}
                  value={bureau.value}
                  onChange={(e) =>
                    setBureau({
                      checked: bureau.checked,
                      value: e.value,
                    })
                  }
                  inputid="bureau"
                  disabled={!bureau.checked}
                />
                <label htmlFor="bureau">
                  {bureau.checked ? "Le nombre des Bureaux" : "Bureau"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={armoire.checked}
                  onChange={(e) =>
                    setArmoire({
                      checked: !armoire.checked,
                      value: armoire.checked ? null : armoire.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={armoire.checked}
                  value={armoire.value}
                  onChange={(e) =>
                    setArmoire({
                      checked: armoire.checked,
                      value: e.value,
                    })
                  }
                  inputid="armoire"
                  disabled={!armoire.checked}
                />
                <label htmlFor="armoire">
                  {armoire.checked ? "Le nombre des Armoires" : "Armoire"}
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="grid p-fluid">
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={velo.checked}
                  onChange={(e) =>
                    setVelo({
                      checked: !velo.checked,
                      value: velo.checked ? null : velo.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={velo.checked}
                  value={velo.value}
                  onChange={(e) =>
                    setVelo({
                      checked: velo.checked,
                      value: e.value,
                    })
                  }
                  inputid="velo"
                  disabled={!velo.checked}
                />
                <label htmlFor="velo">
                  {velo.checked ? "Le nombre des Vélos" : "Vélo"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={colis.checked}
                  onChange={(e) =>
                    setColis({
                      checked: !colis.checked,
                      value: colis.checked ? null : colis.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={colis.checked}
                  value={colis.value}
                  onChange={(e) =>
                    setColis({
                      checked: colis.checked,
                      value: e.value,
                    })
                  }
                  inputid="colis"
                  disabled={!colis.checked}
                />
                <label htmlFor="colis">
                  {colis.checked ? "Le nombre des Colis" : "Colis"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={bois.checked}
                  onChange={(e) =>
                    setBois({
                      checked: !bois.checked,
                      value: bois.checked ? null : bois.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={bois.checked}
                  value={bois.value}
                  onChange={(e) =>
                    setBois({
                      checked: bois.checked,
                      value: e.value,
                    })
                  }
                  inputid="bois"
                  disabled={!bois.checked}
                />
                <label htmlFor="bois">
                  {bois.checked ? "Le nombre des bois" : "bois"}
                </label>
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 xl:col-3 my-2">
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon">
                <Checkbox
                  checked={canape.checked}
                  onChange={(e) =>
                    setCanape({
                      checked: !canape.checked,
                      value: canape.checked ? null : canape.value,
                    })
                  }
                />
              </span>
              <span className="p-float-label">
                <InputNumber
                  autoComplete="off"
                  required={canape.checked}
                  value={canape.value}
                  onChange={(e) =>
                    setCanape({
                      checked: canape.checked,
                      value: e.value,
                    })
                  }
                  inputid="canape"
                  disabled={!canape.checked}
                />
                <label htmlFor="canape">
                  {canape.checked ? "Le nombre des Canapé" : "Canapé"}
                </label>
              </span>
            </div>
          </div>
        </div>
        <div className="grid p-fluid">
          <div className="field col-12 ">
            <span className="">
              <Checkbox
                checked={autre.checked}
                onChange={(e) =>
                  setAutre({
                    checked: !autre.checked,
                    value: autre.checked ? null : autre.value,
                  })
                }
              />
              <label htmlFor="cb1" className="px-2 p-checkbox-label">
                Autre
              </label>
            </span>
          </div>
          <div
            className="field col-12 "
            style={!autre.checked ? { display: "none" } : { display: "block" }}
          >
            <span className="p-float-label">
              <InputTextarea
                required={autre.checked}
                value={autre.value}
                rows={5}
                onChange={(e) =>
                  setAutre({
                    checked: autre.checked,
                    value: e.target.value,
                  })
                }
                inputid="autre"
              />
              <label htmlFor="autre">Autre meubles</label>
            </span>
          </div>
        </div>
      </div>

      <div className="flex justify-content-between flex-wrap card-container">
        <Button
          className="align-items-end flex p-button p-button-outlined p-button-secondary p-component"
          label="Back"
          icon="pi pi-times"
          onClick={back}
        />
        <Button
          className="p-button-outlined p-button-success"
          label="Terminer"
          iconPos="right"
          type="submit"
          icon="pi pi-check"
        />
      </div>
    </form>
  );
};
