import React, { useState } from "react";
import styled from "styled-components";
// Components
import AproposBox from "../Elements/AproposBox";
import ClientSlider from "../Elements/ClientSlider";

export default function Apropos() {
  const [aproposTemp, setAproposTemp] = useState([
    {
      id: 1,
      icon: "MARITIME",
      title: "SERVICE DE FRET ROUTIER ET MARITIME",
      text: "Nous sommes les meilleurs du transport maritime et Fret terrestre, avec une garantie complète et un délai record.",
    },
    {
      id: 2,
      icon: "ENTREPOSAGE",
      title: (
        <>
          <span>SERVICE </span>
          <br />
          <span>D’ENTREPOSAGE</span>
        </>
      ),
      text: "Nous vous fournissons les meilleures conditions de stockage dans nos entrepôts dédiés.",
    },
    {
      id: 3,
      icon: "PROFESSIONNEL",
      title: (
        <>
          <span>SERVICE </span>
          <br />
          <span>PROFESSIONNEL</span>
        </>
      ),
      text: "Personnel d’excellence, nous proposons une gamme complète de déménagement de l’emballage jusqu’au transport.",
    },
  ]);
  return (
    <Wrapper id="apropos">
      <div className="lightBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <div className="whiteBg mt-8">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">ELBOUSTANI</h1>
            <h1 className="font38 extraBold">pourquoi nous choisir</h1>
          </HeaderInfo>
          <div className="row textCenter">
            {aproposTemp.map((apropos) => {
              return (
                <div
                  key={apropos.id}
                  className="col-xs-12 col-sm-4 col-md-4 col-lg-4"
                >
                  <AproposBox
                    title={apropos.title}
                    text={apropos.text}
                    icon={apropos.icon}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;
