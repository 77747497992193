import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
import Hero from './../components/Sections/Hero';
import Devis from './../components/Sections/Devis';

export default function Quotation() {
  return (
    <>
      <TopNavbar />
      <Hero />
      <Devis/>
      <Footer />
    </>
  );
}
