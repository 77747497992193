import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import logo from "../../assets/img/logo_elboustani_white.webp";
import { HashLink } from "react-router-hash-link";

export default function Footerg() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper
            className="flexSpaceCenter"
            style={{ padding: "30px 0" }}
          >
            <Link
              className="flexCenter animate pointer"
              to="/"
              smooth={true}
              offset={-80}
            >
              <img src={logo} style={{ height: "50px" }} />
            </Link>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} -{" "}
              <span className="purpleColor font13">Elboustanitrans</span> Tous
              les droits réservés
            </StyleP>

            <HashLink
              className="whiteColor animate pointer font13"
              to="/#accueil"
              smooth={true}
              offset={-80}
            >
              Retour en haut
            </HashLink>
          </InnerWrapper>
        </div>
      </div>

      <Whatsapp href="https://wa.me/+212673835457" target="_blank">
        <i className="pi pi-whatsapp"></i>
      </Whatsapp>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const Whatsapp = styled.a`
  height: 40px;
  width: 40px;
  background: #128c7e;
  position: fixed;
  bottom: 5%;
  right: 30px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  &:hover {
    color: white;
  }
`;
