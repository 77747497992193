import React, { useState } from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
// Components
import ServiceBox from "../Elements/ServiceBox";
// Assets

import service_112x125 from "../../assets/img/services/service_112x125.webp";
import service_197x224 from "../../assets/img/services/service_197x224.webp";
import service_197x333 from "../../assets/img/services/service_197x333.webp";
import service_285x406 from "../../assets/img/services/service_285x406.webp";
import service_426x607 from "../../assets/img/services/service_426x607.webp";

export default function Services() {
  const [servicesTemp, setServicesTemp] = useState([
    {
      id: 1,
      icon: "EMBALLAGE",
      title: "EMBALLAGE",
      subtitle: (
        <>
          <span>On utilise :</span>
          <ul>
            <li>
              <br />
            </li>
            <li>• Carton double Cannelure</li>
            <li>• Plastique bulle</li>
            <li>• Bull kraft</li>
            <li>• Des couvertures spéciaux</li>
          </ul>
        </>
      ),
    },
    {
      id: 2,
      icon: "TRANSFERT_BUREAUX",
      title: "TRANSFERT BUREAUX",
      subtitle: (
        <ul>
          <li>• Bureaux administratifs</li>
          <li>• Unités industrielles</li>
          <li>• Administrations publiques</li>
          <li>• Entreprises privées</li>
          <li>• Matériel informatique</li>
        </ul>
      ),
    },
    {
      id: 3,
      icon: "TRANSPORT_INTERNATIONAL",
      title: "TRANSPORT INTERNATIONAL",
      subtitle: (
        <>
          <span>
            Nos camions TIR assurent une liaison fréquente avec toute l’Europe :
          </span>
          <ul>
            <li>
              <br />
            </li>
            <li>• Europe par voie terrestre</li>
            <li>• Reste du monde par voie maritime/aérienne</li>
          </ul>
        </>
      ),
    },
    {
      id: 4,
      icon: "ARTICLES_PARTICULIERS",
      title: "ARTICLES PARTICULIERS",
      subtitle: (
        <ul>
          <li>• Coffres-forts</li>
          <li>• Pianos</li>
          <li>• Tables de billards/Snooker</li>
          <li>• Maquettes</li>
          <li>• Objets archéologiques</li>
        </ul>
      ),
    },
    {
      id: 5,
      icon: "MANUTENTION",
      title: "MANUTENTION",
      subtitle: (
        <ul>
          <li>
            <br />
          </li>
          <li>• Chargement</li>
          <li>• Déchargement</li>
        </ul>
      ),
    },
    {
      id: 6,
      icon: "FORMALITES_DOUANIERES",
      title: "FORMALITES DOUANIERES",
      subtitle:
        "Notre entreprise est agrée en douane à l’import comme à l’export.",
    },
    {
      id: 7,
      icon: "DEMONTAGE_REMONTAGE",
      title: "DEMONTAGE REMONTAGE",
      subtitle: (
        <>
          <span>On utilise</span>
          <br /> <span>des outils convenables</span>
          <br /> <span>pour chaque type de meuble</span>
        </>
      ),
    },
    {
      id: 8,
      icon: "TRANSPORT_NATIONAL",
      title: "TRANSPORT NATIONAL",
      subtitle:
        "Nos camions desservent périodiquement sur tout le territoire national.",
    },
  ]);
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <h4 className="font12 semiBold">
                  DÉMÉNAGEMENT NATIONAL, INTERNATIONAL & GARDE-MEUBLES
                </h4>
                <h2 className="font40 extraBold">El-Boustani Transport ​</h2>
                <p className="font12">
                  Pionnier de l’industrie de la mobilité au Maroc, Nous sommes
                  considérées aujourd’hui l’un des leaders grâce à la vision
                  innovante des dirigeants qui se portent depuis près de 10 ans.
                  En gardant à l’esprit les besoins en constante évolution de
                  nos clients, nous fournissons des services de déménagement au
                  Maroc et international, ainsi que le déménagement au Maroc et
                  de stockage de meubles sur mesure partout dans le Maroc.
                </p>
                <ButtonsRow
                  className="flexNullCenter"
                  style={{ margin: "30px 0" }}
                >
                  <DevisButton style={{ width: "240px" }}>
                    <HashLink to="/devis" smooth={true} offset={-80}>
                      Demander votre devis
                    </HashLink>
                  </DevisButton>
                  <ContactButton style={{ width: "190px", marginLeft: "15px" }}>
                    <HashLink to="/#contactez" smooth={true} offset={-80}>
                      Contactez nous
                    </HashLink>
                  </ContactButton>
                </ButtonsRow>
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={service_285x406} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={service_197x333} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={service_197x224} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={service_112x125} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
        <div className="container mt-8">
          <HeaderInfo>
            <h1 className="font40 extraBold">Services</h1>
            <h1 className="font38 extraBold">Ce Que Nous Faisons</h1>
          </HeaderInfo>
          <ServiceBoxRow className="flex flex-wrap">
            {servicesTemp.map((service) => {
              return (
                <ServiceBoxWrapper key={service.id}>
                  <ServiceBox
                    icon={service.icon}
                    title={service.title}
                    subtitle={service.subtitle}
                  />
                </ServiceBoxWrapper>
              );
            })}
          </ServiceBoxRow>
        </div>
      </div>

      <div className="lightBg">
        <div className="container">
          <AdvertisingSecond className="flexSpaceCenter">
            <AddLeftSecond>
              <AddLeftInner>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={service_426x607} alt="add" />
                </ImgWrapper>
              </AddLeftInner>
            </AddLeftSecond>
            <AddRightSecond>
              <h4 className="font15 semiBold">
                DÉMÉNAGEMENT NATIONAL, INTERNATIONAL & GARDE-MEUBLES
              </h4>
              <h2 className="font40 extraBold">El-Boustani Transport</h2>
              <p className="font12">
                Opérant depuis 10 ans, et l'un des leaders mondiale dans
                l'industrie de la mobilité et logistique. Nos équipes
                professionnels, expérimentées et ponctuelles peuvent vous offrir
                le déménagement le plus paisible ( Maroc ou Europe) Tous les
                types de meubles sont transportable pour nous.
              </p>
              <ButtonsRowSecond
                className="flexNullCenter"
                style={{ margin: "50px 0 30px" }}
              >
                <DevisButton style={{ width: "240px" }}>
                  <HashLink to="/devis" smooth={true} offset={-80}>
                    Demander votre devis
                  </HashLink>
                </DevisButton>
                <ContactButton style={{ width: "190px", marginLeft: "15px" }}>
                  <HashLink to="/#contactez" smooth={true} offset={-80}>
                    Contactez nous
                  </HashLink>
                </ContactButton>
              </ButtonsRowSecond>
            </AddRightSecond>
          </AdvertisingSecond>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  ${
    "" /* @media (max-width: 860px) {
    flex-direction: column;
  } */
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  text-align: center;
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    height: 100px;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const ContactButton = styled.div`
  a {
    border: 1px solid #707070;
    background-color: transparent;
    padding: 15px 25px;
    outline: none;
    color: #707070;
    border-radius: 1rem;
    &:hover {
      border: 1px solid #f2b300;
      color: #f2b300;
    }
  }

  @media (max-width: 600px) {
    margin-left: 0px !important;
  }
`;
const DevisButton = styled.div`
  a {
    background-color: #f2b300;
    padding: 15px 25px;
    outline: none;
    border-radius: 1rem;
    color: white;
  }
`;

const AdvertisingSecond = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRowSecond = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    height: 100px;
  }
`;
const AddLeftSecond = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRightSecond = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
