import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import logo from "../../assets/img/logo_elboustani_white.webp";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <img src={logo} style={{ height: "80px" }} />
        </div>
        <CloseBtn
          onClick={() => toggleSidebar(!sidebarOpen)}
          className="animate pointer"
        >
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>

      <UlStyle className="flexNullCenter flexColumn">
        <li className="semiBold font15 pointer">
          <HashLink
            onClick={() => toggleSidebar(!sidebarOpen)}
            className={`whiteColor ${
              window.location.hash === "#accueil" ? "active" : ""
            }`}
            style={{ padding: "10px 15px" }}
            to="/#accueil"
            smooth={true}
            offset={-60}
          >
            Accueil
          </HashLink>
        </li>
        <li className="semiBold font15 pointer">
          <HashLink
            onClick={() => toggleSidebar(!sidebarOpen)}
            className={`whiteColor ${
              window.location.hash === "#services" ? "active" : ""
            }`}
            style={{ padding: "10px 15px" }}
            to="/#services"
            smooth={true}
            offset={-60}
          >
            Services
          </HashLink>
        </li>
        <li className="semiBold font15 pointer">
          <HashLink
            onClick={() => toggleSidebar(!sidebarOpen)}
            className={`whiteColor ${
              window.location.hash === "#apropos" ? "active" : ""
            }`}
            style={{ padding: "10px 15px" }}
            to="/#apropos"
            smooth={true}
            offset={-60}
          >
            À Propos
          </HashLink>
        </li>
        <li className="semiBold font15 pointer">
          <HashLink
            onClick={() => toggleSidebar(!sidebarOpen)}
            className={`whiteColor ${
              window.location.hash === "#packs" ? "active" : ""
            }`}
            style={{ padding: "10px 15px" }}
            to="/#packs"
            smooth={true}
            offset={-60}
          >
            Packs
          </HashLink>
        </li>
        <li className="semiBold font15 pointer">
          <HashLink
            onClick={() => toggleSidebar(!sidebarOpen)}
            className={`whiteColor ${
              window.location.hash === "#contactez" ? "active" : ""
            }`}
            style={{ padding: "10px 15px" }}
            to="/#contactez"
            smooth={true}
            offset={-60}
          >
            Contactez
          </HashLink>
        </li>
      </UlStyle>
      <UlStyleButton className="flexSpaceCenter">
        <span id="devis" className="semiBold font15 pointer flexCenter">
          <Link
            to="/devis"
            className="radius8 lightBg"
            style={{ padding: "10px 15px" }}
          >
            Demande de devis
          </Link>
        </span>
      </UlStyleButton>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
const UlStyleButton = styled.ul`
  padding: 40px;

  justify-content: center;
`;
