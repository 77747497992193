import React, { useState } from "react";
import styled from "styled-components";
// Components
import PacksTable from "../Elements/PacksTable";

export default function Packs() {
  const [packTemp, setPackTemp] = useState([
    {
      id: 1,
      icon: "roller",
      price: 29.99,
      text: "Profitez de notre offre basique, simple et efficace.",
      title: (
        <>
          <span>Pack </span>
          <br />
          <span>Eco</span>
        </>
      ),
      offers: [
        { name: "Transport", cheked: true },
        { name: "Manutention", cheked: true },
        { name: "Emballage", cheked: false },
        { name: "Démontage/ Remontage", cheked: false },
        { name: "Mise en place", cheked: false },
        { name: "Formalités Douane", cheked: false },
      ],
    },
    {
      id: 2,
      icon: "monitor",
      price: 29.99,
      text: "Etant la plus vendue, découvrez l'offre idéale des entreprises et particuliers.",
      title: (
        <>
          <span>Pack </span>
          <br />
          <span>Meduim</span>
        </>
      ),
      offers: [
        { name: "Transport", cheked: true },
        { name: "Manutention", cheked: true },
        { name: "Emballage", cheked: true },
        { name: "Démontage/ Remontage", cheked: true },
        { name: "Mise en place", cheked: false },
        { name: "Formalités Douane", cheked: false },
      ],
    },
    {
      id: 3,
      icon: "browser",
      price: 29.99,
      text: "Profitez d'un accompagnement total de nos experts du déménagement.",
      title: (
        <>
          <span>Pack </span>
          <br />
          <span>International</span>
        </>
      ),
      offers: [
        { name: "Transport", cheked: true },
        { name: "Manutention", cheked: true },
        { name: "Emballage", cheked: true },
        { name: "Démontage/ Remontage", cheked: true },
        { name: "Mise en place", cheked: true },
        { name: "Formalités Douane", cheked: true },
      ],
    },
  ]);
  return (
    <Wrapper id="packs" className="pricing">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Packs</h1>
            <h1 className="font38 extraBold">Nous proposons nos forfaits</h1>
          </HeaderInfo>
          <TablesWrapper className="flexSpaceNull">
            {packTemp.map((pack) => {
              return (
                <TableBox key={pack.id}>
                  <PacksTable
                    title={pack.title}
                    icon={pack.icon}
                    price={pack.price}
                    offers={pack.offers}
                    text={pack.text}
                  />
                </TableBox>
              );
            })}
          </TablesWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0;
`;
const HeaderInfo = styled.div`
  margin-bottom: 50px;
  text-align: center;
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
