import React from "react";
import styled from "styled-components";
// Assets
import TRANSPORT_NATIONAL from "../../assets/img/services/TRANSPORT_NATIONAL.webp";
import TRANSPORT_INTERNATIONAL from "../../assets/img/services/TRANSPORT_INTERNATIONAL.webp";
import TRANSFERT_BUREAUX from "../../assets/img/services/TRANSFERT_BUREAUX.webp";
import MANUTENTION from "../../assets/img/services/MANUTENTION.webp";
import FORMALITES_DOUANIERES from "../../assets/img/services/FORMALITES_DOUANIERES.webp";
import EMBALLAGE from "../../assets/img/services/EMBALLAGE.webp";
import DEMONTAGE_REMONTAGE from "../../assets/img/services/DEMONTAGE_REMONTAGE.webp";
import ARTICLES_PARTICULIERS from "../../assets/img/services/ARTICLES_PARTICULIERS.webp";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  switch (icon) {
    case "TRANSPORT_NATIONAL":
      getIcon = TRANSPORT_NATIONAL;
      break;
    case "TRANSPORT_INTERNATIONAL":
      getIcon = TRANSPORT_INTERNATIONAL;
      break;
    case "TRANSFERT_BUREAUX":
      getIcon = TRANSFERT_BUREAUX;
      break;
    case "MANUTENTION":
      getIcon = MANUTENTION;
      break;
    case "FORMALITES_DOUANIERES":
      getIcon = FORMALITES_DOUANIERES;
      break;
    case "EMBALLAGE":
      getIcon = EMBALLAGE;
      break;
    case "DEMONTAGE_REMONTAGE":
      getIcon = DEMONTAGE_REMONTAGE;
      break;
    case "ARTICLES_PARTICULIERS":
      getIcon = ARTICLES_PARTICULIERS;
      break;
    default:
      getIcon = EMBALLAGE;
      break;
  }

  return (
    <Wrapper className="flex flexColumn justify-content-between align-items-center">
      <ImgStyle src={getIcon}></ImgStyle>
      {/* <IconStyle>{getIcon}</IconStyle> */}
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  padding: 50px 20px;
  height: 340px;
  border-radius: 20px;
  -webkit-box-shadow: -1px -1px 11px rgb(200 200 200 / 50%);
  text-align: center;
  @media (max-width: 860px) {
    width: 80%;
    margin: auto;
  }
`;
const ImgStyle = styled.img`
  height: 40px;
  width: 40px;
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${
    "" /* @media (max-width: 860px) {
  } */
  }
`;
const SubtitleStyle = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  text-align: initial;
`;
