import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Apropos from "../components/Sections/Apropos";
import Packs from "../components/Sections/Packs";
import Footer from "../components/Sections/Footer"
import Address from "../components/Sections/Address";

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Apropos />
      <Services />
      <Packs />
      <Address />
      <Footer />
    </>
  );
}


