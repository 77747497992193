import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataContext } from "../Sections/Devis";
import { Dropdown } from "primereact/dropdown";

export const InfoForm = ({ next }) => {
  const { data, setData } = useContext(DataContext);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [civilite, setCivilite] = useState(null);
  const civiliteOptions = ["Mr", "Mme", "mlle"];
  const handleSubmit = (e) => {
    data.info = {
      civilite,
      nom,
      prenom,
      email,
      telephone,
    };
    setData(data);
    next();
    e.preventDefault();
  };
  useEffect(() => {
    if (data.info) {
      setNom(data.info.nom);
      setPrenom(data.info.prenom);
      setEmail(data.info.email);
      setTelephone(data.info.telephone);
      setCivilite(data.info.civilite);
    }
  }, []);
  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div className="card">
        <div className="mb-3 formgrid grid">
          <div className="field col-12 md:col-4 col">
            <span className="p-float-label">
              <Dropdown
                required
                className="w-full"
                inputid="civilite"
                value={civilite}
                options={civiliteOptions}
                onChange={(e) => setCivilite(e.value)}
              />
              <label htmlFor="TypeDEtransfert">Civilité*</label>
            </span>
          </div>
          <div className="field col-12 md:col-4 col">
            <span className="p-float-label">
              <InputText
                className=" block w-full"
                required
                id="nom"
                name="nom"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
              <label htmlFor="nom">Nom*</label>
            </span>
          </div>
          <div className="field col-12 md:col-4 col">
            <span className="p-float-label">
              <InputText
                className=" block w-full"
                required
                id="prenom"
                name="prenom"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
              <label htmlFor="prenom">Prenom*</label>
            </span>
          </div>
        </div>
        <div className="mb-5 field">
          <span className="p-float-label">
            <InputText
              className=" block w-full"
              required
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email">Email*</label>
          </span>
        </div>
        <div className="mb-5 field">
          <span className="p-float-label">
            <InputText
              className=" block w-full"
              required
              id="telephone"
              name="telephone"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
            />
            <label htmlFor="telephone">Numero de telepohne*</label>
          </span>
        </div>
      </div>

      <div className="flex justify-content-end flex-wrap card-container">
        <Button
          className="p-button-outlined p-button-info"
          label="Suivant"
          iconPos="right"
          icon="pi pi-angle-right"
          type="submit"
        />
      </div>
    </form>
  );
};
